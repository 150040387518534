<template>
  <div class="files-field">
    <VueFileAgent
      ref="vueFileAgent"
      v-model="filesRecord"
      class="files-field__content"
      :theme="'list'"
      :class="{
        'files-field__content--has-errors': errorMessages.length,
      }"
      :multiple="true"
      :deletable="true"
      :meta="true"
      :accept="'image/*, .pdf'"
      :max-size="'10MB'"
      :max-files="10"
      :help-text="$t('helpText')"
      :error-text="{
        type: $t('errorText.type'),
        size: $t('errorText.size'),
      }"
      @select="filesSelected($event)"
      @beforedelete="onBeforeDelete($event)"
      @delete="$emit('delete')"
      @blur="$emit('blur')"
      @input="$emit('input')"
      @click="$emit('click')"
    >
      <!-- File item -->
      <template #file-preview="slotProps">
        <v-list-item
          :key="slotProps.index"
          dense
          :style="{ backgroundColor: slotProps.fileRecord.error && !slotProps.fileRecord.url() ? '#ff5252' : '#f5f5f5' }"
        >
          <v-list-item-avatar>
            <v-icon v-text="getIconFromExtension(slotProps.fileRecord.ext())" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="slotProps.fileRecord.name()" />
          </v-list-item-content>

          <v-list-item-action>
            <v-btn v-if="slotProps.fileRecord.url()" icon target="href" :style="{ zIndex: 99}" :href="slotProps.fileRecord.url()">
              <v-icon v-text="'visibility'" />
            </v-btn>
          </v-list-item-action>
          <v-list-item-action>
            <v-btn icon :style="{ zIndex: 99}" @click="removeFileRecord(slotProps.fileRecord)">
              <v-icon v-text="'close'" />
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>

      <template #file-preview-new>
        <div key="new" class="my-8">
          <a href="#" class="">{{ $t('helpText') }}</a> {{ $t('dragAndDrop') }}
        </div>
      </template>
    </vuefileagent>
    <v-input :error-messages="errorMessages" error />
  </div>
</template>

<script>
export default {
  name: 'FilesField',
  props: {
    value: { type: Array, default: () => [] },
    errorMessages: { type: String, default: '' },
  },
  data() {
    return {
      urlInitial: null,
      filesRecord: [],
    }
  },

  async created() {
    const parseModelFile = modelFile => ({
      url: modelFile.publicUrl,
      urlResized: modelFile.publicUrl,
      error: null,
      type: modelFile.type,
      modelFile,
      name: modelFile.name,
      ext: modelFile.name.split('.').pop(),
    })
    this.filesRecord = await Promise.all(
      this.value.map(async modelFile => (!(modelFile instanceof File) ? parseModelFile(modelFile) : modelFile)),
    )
  },
  methods: {
    getIconFromExtension(ext) {
      if (['png', 'jpg', 'jpeg'].includes(ext)) return 'insert_photo'
      if (ext === 'pdf') return 'picture_as_pdf'
      return ''
    },
    removeFileRecord(fileRecord) {
      return this.$refs.vueFileAgent.removeFileRecord(fileRecord)
    },
    filesSelected() {
      const validFileRecords = this.filesRecord.filter(fileRecord => !fileRecord.error)
      this.$emit('input', validFileRecords.map(v => (v.modelFile ? v.modelFile : v.file)))
    },
    onBeforeDelete(fileRecord) {
      const i = this.filesRecord.indexOf(fileRecord)
      if (i !== -1) {
        this.filesRecord.splice(i, 1)
        this.$emit('input', this.filesRecord.map(v => (v.modelFile ? v.modelFile : v.file)))
      }
    },
  },
  i18n: {
    messages: {
      en: {
        helpText: 'Choose images or pdf',
        dragAndDrop: 'Drag & drop her',
        errorText: {
          type: 'Invalid file type. Only image and pdf  Allowed',
          size: 'Files should not exceed 10MB in size',
        },
      },
      es: {
        helpText: 'Seleccionar imagenes o pdf',
        dragAndDrop: 'Arrastra el fichero aquí',
        errorText: {
          type: 'Tipo de archivo inválido. Sólo se permiten imagenes y pdf',
          size: 'Los archivos no deben exceder los 10MB de tamaño',
        },
      },
    },
  },
}
</script>

<style lang="scss">
.files-field {
  .files-field__content {
    &--has-errors {
      .vue-file-agent.file-input-wrapper {
        border-color: #ff5252;
      }
    }
  }
}

.file-input {
  z-index: 0 !important;
}
</style>
